import * as React from "react";
import { OrderListItem } from "src/types";
import Order from "./Order";
import styles from "./Orders.module.css";

interface Props {
  orders: OrderListItem[];
}

const Orders: React.SFC<Props> = props => {
  return (
    <div className={styles.wrapper}>
      {props.orders.map(curr => (
        <div className={styles.item} key={curr._id}>
          <Order data={curr} />
        </div>
      ))}
    </div>
  );
};

export default Orders;
