import * as React from "react";
import { connect } from "react-redux";
import Api from "src/api";
import RecommendedPlaylistsContainer from "src/components/RecommendedPlaylistsContainer";
import Typography from "src/components/Typography";
import { RECOMMENDED_PLAYLISTS_TYPES } from "src/consts";
import { OrderListItem, PageProps, User } from "src/types";
import Orders from "./Orders";
import styles from "./Orders.module.css";

type State = {
  orders: OrderListItem[];
};

interface ConnectStateProps {
  user?: User;
}
type Props = PageProps & ConnectStateProps & {};

class OrdersContainer extends React.Component<Props, State> {
  public state = {
    orders: [],
  };

  public async componentDidMount() {
    if (!this.props.user?.email) {
      return;
    }
    const res = await Api.getOrders();
    this.setState({
      orders: res.data,
    });
  }

  public render() {
    if (!this.state.orders) {
      return (
        <div className={styles.wrapper}>
          {this.renderEmpty()}
          <RecommendedPlaylistsContainer
            type={RECOMMENDED_PLAYLISTS_TYPES.ACCOUNT_PAYED}
          />
        </div>
      );
    }
    return (
      <div className={styles.wrapper}>
        <Orders orders={this.state.orders} />
        <RecommendedPlaylistsContainer
          type={RECOMMENDED_PLAYLISTS_TYPES.ACCOUNT_PAYED}
        />
      </div>
    );
  }

  private renderEmpty() {
    return (
      <div className={styles.emptyWrapper}>
        <Typography type="h3">У вас пока еще нет покупок :)</Typography>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect<ConnectStateProps>(mapStateToProps)(OrdersContainer);
