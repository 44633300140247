import * as React from "react";
import Seo from "src/components/Seo";
import OrdersComponent from "src/routes/Orders";
import { PageProps } from "src/types";

const Orders: React.SFC<PageProps> = props => {
  return (
    <div>
      <Seo pageTitle="Оплачено" />
      <OrdersComponent {...props} />
    </div>
  );
};

export default Orders;
