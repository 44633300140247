import * as React from "react";
import { connect } from "react-redux";
import Api from "src/api";
import RecommendedVideos from "src/components/RecommendedVideos";
import TopVideos from "src/components/TopVideos";
import { User, Video } from "src/types";

type ConnectStateProps = {
  user?: User;
};

interface Props extends ConnectStateProps {
  type: string;
  renderTopVideo?: boolean;
}

type State = {
  videos?: Video[];
};

class RecommendedPlaylistsContainer extends React.Component<Props, State> {
  public state: State = {
    videos: null,
  };

  public async componentDidMount() {
    await this.getRecommendedVideos();
  }

  public async componentDidUpdate(prevProps: Props) {
    if (this.props.user?._id && prevProps?.user?._id !== this.props.user?._id) {
      await this.getRecommendedVideos();
    }
  }

  public render() {
    if (!this.state.videos?.length) {
      return null;
    }

    if (this.props.renderTopVideo) {
      return <TopVideos videos={this.state.videos} />;
    }

    return <RecommendedVideos videos={this.state.videos} hideDisableIcon />;
  }

  private getRecommendedVideos = async () => {
    try {
      const res = await Api.getRecommendedVideos(this.props.type);
      this.setState({ videos: res.data?.videos });
    } catch (error) {
      // tslint:disable-next-line: no-unsafe-any
      console.error(error && error.message);
    }
  };
}

const mapStateToProps = (state) => ({
  // tslint:disable no-unsafe-any
  user: state.user.user,
});

export default connect<ConnectStateProps>(mapStateToProps)(
  RecommendedPlaylistsContainer
);
