import { Link } from "gatsby";
import React from "react";
import ArrowButton from "src/components/ArrowButton";
import { formatDate } from "src/components/DateFormater";
import Typography from "src/components/Typography";
import { GOOD_TYPES_LINKS } from "src/consts";
import icon from "src/images/film-cam-icon.svg";
import { OrderListItem } from "src/types";
import styles from "./Order.module.css";

interface Props {
  data: OrderListItem;
}

const Order = ({ data }: Props) => {
  return (
    <div className={styles.orderWrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.iconWrapper}>
          <img src={icon} alt="icon" />
        </div>
        <div className={styles.textWrapper}>
          <Typography type="h5">{data.good.name}</Typography>
          <Typography type="text">{formatDate(data.updatedAt)}</Typography>
          <Typography type="boldText" className={styles.price}>{`${
            data.price / 100
          } Р`}</Typography>
        </div>
      </div>
      <Link to={GOOD_TYPES_LINKS[data.good.type]}>
        <ArrowButton hideArrowOnMobile />
      </Link>
    </div>
  );
};

export default Order;
