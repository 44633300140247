import * as React from "react";
import Video from "src/components/Video";
import { Video as VideoType } from "src/types";
import styles from "./TopVideos.module.css";

type Props = {
  videos: VideoType[];
};

const TopVideos = (props: Props) => (
  <div className={styles.videosWrapper}>
    {props.videos.map((curr, i) => (
      <Video key={i} video={curr} hideDisableIcon />
    ))}
  </div>
);

export default TopVideos;
